"use strict";

//Menu
let toggleBtn = document.querySelector(".c-header__nav-btn");
let nav = document.querySelector(".c-header__nav");
let dropMenuLink = document.querySelectorAll(".c-nav__link_drop");
let dropMenuLinkArrow = document.querySelectorAll(".c-nav__dropdown-arrow");
let dropMenu = document.querySelectorAll(".c-nav__dropdown-list");

toggleBtn.addEventListener("click", function () {
  toggleBtn.classList.toggle("is-active");
  nav.classList.toggle("is-active");
});

dropMenuLink.forEach((item, index) => {
  item.addEventListener("click", function (event) {
    if (event.target === dropMenuLinkArrow[index]) {
      event.preventDefault();
      dropMenuLinkArrow[index].classList.toggle("is-active");
      dropMenu[index].classList.toggle("is-active");

      if (dropMenu[index].classList.contains("is-active")) {
        dropMenu[index].style.height = `${
          (dropMenu[index].children[0].offsetHeight +
            parseInt(
              getComputedStyle(dropMenu[index].children[0]).marginBottom
            )) *
          dropMenu[index].childElementCount
        }px`;
      } else {
        dropMenu[index].style.height = `0px`;
      }
    }
  });
});

//lang
let langSelector = document.querySelector(".c-header__lang-select");
let langList = document.querySelector(".c-header__lang-list");

langSelector.addEventListener("click", function () {
  langList.classList.toggle("is-active");
});
